import Layout from "@/components/Layout";
import SEO from "@/components/SEO";
import { BreadCrumb } from "@/components/Shared/BreadCrumb";
import React from "react";

const CareersPage: React.FC = () => {
  return (
    <Layout>
      <SEO
        contentfulSeo={{
          documentTitle: "Careers | Zartek Qatar",
          metaDescription:
            "Get hired in one of the best mobile app development companies in qatar.",
          metaKeywords: [
            "job openings in top mobile app development company in qatar",
            " top mobile app development company in qatar",
            " job vacancies at top android app development company in qatar",
            " openings in mobile app development companies in qatar",
            " openings in game development companies in qatar",
            " careers in mobile app development companies in qatar",
            " job openings in flutter development companies in qatar",
            " job openings for android developers in qatar",
            " job openings for ios developers in qatar",
            " job openings for flutter developers in qatar",
            " job openings for game developers in qatar",
          ],
          metaTitle: "Careers | Zartek Qatar",
          metaUrl: "https://www.zartek.qa/careers/",
        }}
      />
      <BreadCrumb
        routingPaths={[{ path: "/", routeName: "Home" }]}
        activePath={{ routeName: "Careers" }}
        title="Careers"
      />
      <div className="pb-5 mt-5">
        <div className="pt-3">
          <iframe
            src="https://recruitcareers.zappyhire.com/en/zartek"
            title="zartek careers"
            frameBorder="0"
            height="100%"
            width="100%"
            style={{ minHeight: "100vh" }}
          ></iframe>
        </div>

        <div className="container mt-3">
          <strong>Note</strong> : Zartek or any employee will never ask any candidate favours,
          gifts, money. If you come across any suspicious behaviour, please send a mail to
          info@zartek.in. Please ignore job advertisements of a dubious nature. Do not share
          personal information, including OTPs sent to your mobile phone. Never transfer money to
          strangers or anyone you have never met.
        </div>
      </div>
    </Layout>
  );
};

export default CareersPage;
